import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  useTrackOnce,
  useTracking,
} from '@utilitywarehouse/partner-tracking-react';
import { useSelector } from 'react-redux';

import {
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_SOGEA,
  TECHNOLOGY_LLU,
} from 'redux/modules/Broadband/constants';
import { SummaryContainer } from 'redux/modules/Broadband/container';
import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import { Tab, TabLabel, Tabs } from 'components/modules/Shared/Tabs';
import ErrorPage from 'components/modules/Error/ErrorPage';
import { EVENT_TYPE, EVENTS } from 'app/lib/analytics/constants';
import HomephoneOptions from 'modules/Broadband/HomephoneOptions';
import CollectionForm from 'app/redux/modules/HomeAudit/CollectionForm/container';
import { SERVICES } from 'app/redux/modules/HomeAudit/constants';
import { price } from 'app/constants/propTypes';
import AlertMessage from 'modules/Shared/AlertMessage';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';

import TariffSelectionCard from './TariffSelection/Card';
import TariffSkeleton from './TariffSelection/Skeleton';
import Benefits from './Benefits';
import MoreInfoDropdown from './MoreInfoDropdown';
import RouterSelection from './RouterSelection';
import { PageTitle } from './TariffTitle/PageTitle';
import useStyles from './styles';
import { SectionTitle } from './TariffTitle/SectionTitle';

const Broadband = ({
  tariffs,
  selectedTariffId,
  technology,
  selectBroadbandTechnology,
  updateBroadbandTariff,
  updateBroadbandRouter,
  hasFttpTariffs,
  isOnlyFttp,
  homephoneRequired,
  isLoading,
  hasError,
  broadbandInfo,
  setBroadBandMoreInfo,
  selectedRouterName,
  updateSelectedEeroDevices,
  selectedEeroDevices,
  eeroDialogOpen,
  setEeroDialogOpen,
  showAlertBox,
  hasTariffs,
  putBroadbandRequest,
  getBroadbandRequest,
  homephoneOptions,
  updateHomephoneOption,
  homephoneSectionComplete,
  isTenant,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const analytics = useTracking();
  const routerSelectionRef = useRef(null);
  const homephoneSelectionRef = useRef(null);
  const tariffSelectionRef = useRef();
  const stickyNavFlagEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.CTA_STICKY_NAVIGATION_ENABLED)
  );

  const { variant: stickyNavVariant } = useFeature(features.STICKY_NAV);
  const stickyNavEnabled =
    stickyNavFlagEnabled ||
    stickyNavVariant === variations[features.STICKY_NAV].ON;

  const trackOnceTariffDisplayed = useTrackOnce();
  const trackOnceTariffSelected = useTrackOnce();
  const trackOnceRouterOptionSelected = useTrackOnce();

  const showTabs = !isOnlyFttp && hasFttpTariffs && technology;

  useScrollIntoView(
    tariffSelectionRef,
    ({ visible }) => {
      if (visible) trackOnceTariffDisplayed(EVENTS.BROADBAND_TARIFFS_DISPLAYED);
    },
    { threshold: 0.4 }
  );

  useEffect(() => {
    getBroadbandRequest();
  }, [getBroadbandRequest]);

  if (hasError && !hasTariffs)
    return (
      <ErrorPage showImage={false} classes={{ root: classes.errorRoot }} />
    );

  const onChangeTab = (_event, tech) => {
    selectBroadbandTechnology(tech);
    analytics.track(EVENTS.BROADBAND_TABS_SELECT, {
      tab: tech,
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
  };

  const onSelectTariff = (tariff) => {
    updateBroadbandTariff(tariff);
    if (!desktop || stickyNavEnabled) {
      routerSelectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    trackOnceTariffSelected(EVENTS.BROADBAND_TARIFF_SELECTED, {
      tariff: tariff.meta.name,
    });
  };

  const onRouterChange = (router) => {
    updateBroadbandRouter(router);
    if (technology !== TECHNOLOGY_FTTP && (!desktop || stickyNavEnabled)) {
      homephoneSelectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    trackOnceRouterOptionSelected(EVENTS.BROADBAND_ROUTER_SELECTED, {
      router: router,
    });
  };

  return (
    <>
      <CollectionForm
        service={SERVICES.BROADBAND}
        tooltip="If your broadband is a part of a bundle, please only enter the cost of your broadband."
        collectDate
        collectionFormClasses={{ root: classes.titleSection }}
      />
      <SectionCentered classes={{ root: showTabs && classes.titleSection }}>
        <PageTitle technology={technology} showTabs={showTabs} />

        {showTabs && (
          <Tabs
            value={technology}
            onChange={onChangeTab}
            indicatorColor="primary"
            variant="fullWidth"
          >
            {tariffs.some(
              (tariff) => tariff.meta.technology === TECHNOLOGY_FTTP
            ) && (
              <Tab
                wrapped
                data-test-id={`bb_tab_${TECHNOLOGY_FTTP}`}
                label={<TabLabel label="Full Fibre" icon="images/Launch.svg" />}
                value={TECHNOLOGY_FTTP}
              />
            )}
            {tariffs.some(
              (tariff) => tariff.meta.technology === TECHNOLOGY_FTTC
            ) && (
              <Tab
                wrapped
                data-test-id={`bb_tab_${TECHNOLOGY_FTTC}`}
                label={<TabLabel label="Part Fibre" icon="images/Car.svg" />}
                value={TECHNOLOGY_FTTC}
              />
            )}
            {tariffs.some(
              (tariff) => tariff.meta.technology === TECHNOLOGY_SOGEA
            ) && (
              <Tab
                wrapped
                data-test-id={`bb_tab_${TECHNOLOGY_SOGEA}`}
                label={<TabLabel label="Part Fibre" icon="images/Car.svg" />}
                value={TECHNOLOGY_SOGEA}
              />
            )}
            {tariffs.some(
              (tariff) => tariff.meta.technology === TECHNOLOGY_LLU
            ) && (
              <Tab
                wrapped
                data-test-id={`bb_tab_${TECHNOLOGY_LLU}`}
                label={<TabLabel label="Copper" icon="images/Bicycle.svg" />}
                value={TECHNOLOGY_LLU}
              />
            )}
            {/* All table may be needed in the future; comment useful for git blame */}
          </Tabs>
        )}
      </SectionCentered>
      <SectionCentered
        sectionCenteredClasses={{
          root: showTabs ? classes.tariffListWrapperAlt : undefined,
        }}
      >
        <SectionTitle technology={technology} showTabs={showTabs} />
        {technology && (
          <Benefits technology={technology} showTariffTable={false} />
        )}
        <div className={classes.tariffList} ref={tariffSelectionRef}>
          {isLoading && (
            <>
              <div className={classes.tariffListItemWrapper}>
                <TariffSkeleton className={classes.tariffListItem} />
              </div>
              <div className={classes.tariffListItemWrapper}>
                <TariffSkeleton className={classes.tariffListItem} />
              </div>
            </>
          )}
          {!isLoading &&
            tariffs
              .filter(({ meta }) => {
                if (showTabs) {
                  return meta.technology === technology;
                }
                return true;
              })
              .map((tariff) => (
                <div
                  key={tariff.meta.id}
                  className={classes.tariffListItemWrapper}
                >
                  <TariffSelectionCard
                    className={classes.tariffListItem}
                    tariff={tariff}
                    tariffSelected={selectedTariffId === tariff.meta.id}
                    onSelectTariff={onSelectTariff}
                  />
                </div>
              ))}
        </div>

        {!isLoading && showAlertBox && (
          <AlertMessage className={classes.alert}>
            {isOnlyFttp && (
              <Typography>
                Our Fibre 40, Fibre 80, Full Fibre 100, Full Fibre 500 and Full
                Fibre 900 plans are broadband-only services, so you’ll no longer
                have a landline to make or receive calls. You’ll need a mobile
                to make calls (including to 999 or 112). Please note: if you use
                an alarm or medical dialer that requires a phone line, it will
                not work with this service.
              </Typography>
            )}
            {!isOnlyFttp && (
              <Typography>
                Our Fibre 40, Fibre 80, Full Fibre 100, Full Fibre 500 and Full
                Fibre 900 plans are broadband-only services, so you’ll no longer
                have a landline to make or receive calls. You’ll need a mobile
                to make calls (including to 999 or 112). Please note: if you use
                an alarm or medical dialer that requires a phone line, it will
                not work with this service. If you need a phone line or would
                like to keep your existing one, you must select from one of the
                following tariffs: Standard, Ultra, Ultra Plus.
              </Typography>
            )}
          </AlertMessage>
        )}
        {!isLoading && (
          <MoreInfoDropdown
            open={broadbandInfo}
            setBroadBandMoreInfo={setBroadBandMoreInfo}
            bottomSpacing={!selectedTariffId}
            isTenant={isTenant}
            displayPromoTerms={tariffs.some(
              (tariff) => tariff?.contract?.promo?.id === 'TBYB'
            )}
          />
        )}
      </SectionCentered>
      <div ref={routerSelectionRef} />
      {!isLoading && selectedTariffId && (
        <RouterSelection
          eeroDialogOpen={eeroDialogOpen}
          onEeroChange={updateSelectedEeroDevices}
          onRouterChange={onRouterChange}
          selectedEero={selectedEeroDevices}
          selectedRouter={selectedRouterName}
          selectedTariff={selectedTariffId}
          setEeroDialogOpen={setEeroDialogOpen}
          tariffs={tariffs}
        />
      )}
      {homephoneRequired && <div ref={homephoneSelectionRef} />}
      {!isLoading &&
        selectedTariffId &&
        selectedRouterName &&
        homephoneRequired && (
          <HomephoneOptions
            homephoneOptions={homephoneOptions}
            setHomephoneOption={updateHomephoneOption}
          />
        )}
      {!isLoading && selectedTariffId && selectedRouterName && (
        <SectionCentered classes={{ root: classes.summaryContainer }}>
          <SummaryContainer />
        </SectionCentered>
      )}
      <NavigationPane
        helpCtaEnabled
        back
        next
        nextHandler={() => {
          putBroadbandRequest(
            selectedTariffId,
            selectedRouterName,
            selectedEeroDevices,
            homephoneOptions
          );
        }}
        nextDisabled={
          isLoading ||
          !selectedTariffId ||
          !selectedRouterName ||
          !homephoneSectionComplete
        }
      />
    </>
  );
};

Broadband.propTypes = {
  updateBroadbandTariff: PropTypes.func.isRequired,
  updateBroadbandRouter: PropTypes.func.isRequired,
  selectBroadbandTechnology: PropTypes.func.isRequired,
  setBroadBandMoreInfo: PropTypes.func.isRequired,
  broadbandInfo: PropTypes.bool.isRequired,
  hasFttpTariffs: PropTypes.bool.isRequired,
  isOnlyFttp: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  selectedTariffId: PropTypes.string,
  selectedTariff: PropTypes.shape({
    contract: PropTypes.shape({
      promo: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
  technology: PropTypes.string,
  tariffs: PropTypes.arrayOf(
    PropTypes.shape({
      tariffLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedRouterName: PropTypes.string,
  updateSelectedEeroDevices: PropTypes.func.isRequired,
  selectedEeroDevices: PropTypes.number.isRequired,
  setEeroDialogOpen: PropTypes.func.isRequired,
  putBroadbandRequest: PropTypes.func.isRequired,
  getBroadbandRequest: PropTypes.func.isRequired,
  eeroDialogOpen: PropTypes.bool.isRequired,
  showAlertBox: PropTypes.bool.isRequired,
  hasTariffs: PropTypes.bool.isRequired,
  homephoneOptions: PropTypes.shape({
    landlineNumber: PropTypes.string,
    isVirginMedia: PropTypes.bool,
    selectedCallPackageId: PropTypes.string.isRequired,
    callPackages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        cost: price,
      })
    ),
  }).isRequired,
  updateHomephoneOption: PropTypes.func.isRequired,
  homephoneSectionComplete: PropTypes.bool,
  homephoneRequired: PropTypes.bool,
  isTenant: PropTypes.func.isRequired,
};

export default Broadband;

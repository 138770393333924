import { makeStyles } from '@material-ui/core/styles';
import { colors, colorsCommon } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 0),
  },
  icon: {
    color: colors.cyan400,
    cursor: 'pointer',

    '& > svg': {
      marginBottom: -6,
    },
  },
  titleInfoIcon: {
    '& > svg': {
      width: 40,
      height: 40,
    },
  },
  sectionTitle: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  sectionTitleInfoIcon: {
    '& > svg': {
      width: 30,
      height: 30,
    },
  },
  link: {
    '&.uwu-TextLink': {
      color: colors.cyan400,
    },
  },
}));

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: colorsCommon.brandMidnight,
    color: colors.white,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  arrow: {
    color: colorsCommon.brandMidnight,
  },
}));

export { useTooltipStyles, useStyles };

import { FIBRE_TARIFF_URL } from 'app/config/externalLinks';
import {
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_LLU,
  TECHNOLOGY_SOGEA,
} from 'app/redux/modules/Broadband/constants';

const tariffTooltipMessageMap = new Map([
  [
    TECHNOLOGY_FTTP,
    'Full Fibre connects your broadband using lightning-fast fibre optic cables directly to your home. It’s the UK’s most reliable broadband technology, giving you faster speeds with fewer dropouts.',
  ],
  [
    TECHNOLOGY_SOGEA,
    'Part Fibre broadband uses a combination of fibre cables and older copper cables to connect to your home.',
  ],
  [
    TECHNOLOGY_FTTC,
    'Part Fibre broadband uses a combination of fibre cables and older copper cables to connect to your home.',
  ],
  [
    TECHNOLOGY_LLU,
    'Copper broadband uses older copper cables to connect to your home.',
  ],
]);

const tariffTooltipLinkMap = new Map([
  [
    TECHNOLOGY_FTTP,
    { url: FIBRE_TARIFF_URL, text: 'More about fibre broadband' },
  ],
  [
    TECHNOLOGY_SOGEA,
    { url: FIBRE_TARIFF_URL, text: 'More about fibre broadband' },
  ],
  [
    TECHNOLOGY_FTTC,
    { url: FIBRE_TARIFF_URL, text: 'More about fibre broadband' },
  ],
  // TODO: add link to copper broadband once ready
  [TECHNOLOGY_LLU, { url: '', text: 'More about copper broadband' }],
]);

const getTooltipMessage = (technology) =>
  tariffTooltipMessageMap.get(technology) ?? '';

const getTooltipLink = (technology) =>
  tariffTooltipLinkMap.get(technology) ?? {};

export { getTooltipMessage, getTooltipLink };

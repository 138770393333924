import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const style = (theme) => ({
  root: {
    display: 'grid',
    gap: `${theme.spacing(2)}px`,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(185px, min-content))',
    },
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 1),
  },
});

const ServiceTiles = ({ children }) => {
  const classes = makeStyles(style)();
  const ref = React.useRef();
  const trackOnce = useTrackOnce();

  useScrollIntoView(
    ref,
    ({ visible }) => {
      if (visible) trackOnce(EVENTS.BUNDLE_VIEWPORT_FULLY_DISPLAYED);
    },
    { threshold: 1.0 }
  );

  return (
    <div ref={ref} className={classes.root}>
      {children}
    </div>
  );
};

ServiceTiles.propTypes = {
  children: PropTypes.node,
};

export default ServiceTiles;

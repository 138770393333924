import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ClickAwayListener, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { TextLink } from '@utilitywarehouse/web-ui';
import clsx from 'classnames';

import {
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_LLU,
  TECHNOLOGY_SOGEA,
} from 'app/redux/modules/Broadband/constants';

import { useTooltipStyles, useStyles } from './styles';
import { getTooltipLink, getTooltipMessage } from './tooltipContents';

const titleMessageMap = new Map([
  [TECHNOLOGY_FTTP, 'Choose your Full Fibre plan'],
  [TECHNOLOGY_SOGEA, 'Choose your Part Fibre plan'],
  [TECHNOLOGY_FTTC, 'Choose your Part Fibre plan'],
  [TECHNOLOGY_LLU, 'Choose your Copper plan'],
]);

const PageTitle = ({ technology, showTabs }) => {
  const classes = useStyles();
  const tooltipStyles = useTooltipStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (showTabs) {
    return (
      <Typography variant="h2" color="primary" className={classes.title}>
        Choose your plan
      </Typography>
    );
  }

  const title = titleMessageMap.get(technology) ?? 'Choose your plan';
  const tooltipMessage = getTooltipMessage(technology);
  const { url, text } = getTooltipLink(technology);

  const renderTooltipContent = () => (
    <>
      {tooltipMessage}
      {url && (
        <>
          <br />
          <br />
          <TextLink
            className={classes.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </TextLink>
        </>
      )}
    </>
  );

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <Typography variant="h2" color="primary" className={classes.title}>
      {title}{' '}
      {tooltipMessage && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            classes={tooltipStyles}
            title={renderTooltipContent()}
            placement="bottom"
            arrow
            onClose={handleTooltipClose}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive
          >
            <span
              className={clsx(classes.icon, classes.titleInfoIcon)}
              onClick={() => setIsTooltipOpen(!isTooltipOpen)}
            >
              <InfoOutlined />
            </span>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Typography>
  );
};

PageTitle.propTypes = {
  technology: PropTypes.string,
  showTabs: PropTypes.bool,
};

export { PageTitle };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ClickAwayListener, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { TextLink } from '@utilitywarehouse/web-ui';
import { colors } from '@utilitywarehouse/colour-system';
import clsx from 'classnames';

import {
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_LLU,
  TECHNOLOGY_SOGEA,
} from 'app/redux/modules/Broadband/constants';

import { getTooltipMessage, getTooltipLink } from './tooltipContents';

import { useTooltipStyles, useStyles } from './styles';

const titleMessageMap = new Map([
  [TECHNOLOGY_FTTP, 'Full Fibre plans'],
  [TECHNOLOGY_SOGEA, 'Part Fibre plans'],
  [TECHNOLOGY_FTTC, 'Part Fibre plans'],
  [TECHNOLOGY_LLU, 'Copper plans'],
]);

const SectionTitle = ({ technology, showTabs }) => {
  const classes = useStyles();
  const tooltipStyles = useTooltipStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (!showTabs) {
    return null;
  }

  const title = titleMessageMap.get(technology) ?? '';

  // Should never happen, but just in case
  if (!title) {
    return null;
  }

  const tooltipMessage = getTooltipMessage(technology);
  const { url, text } = getTooltipLink(technology);

  const renderTooltipContent = () => (
    <>
      {tooltipMessage}
      {url && (
        <>
          <br />
          <br />
          <TextLink
            className={classes.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </TextLink>
        </>
      )}
    </>
  );

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <Typography
      variant="h3"
      color={colors.grey1000}
      className={classes.sectionTitle}
    >
      {title}{' '}
      {tooltipMessage && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            classes={tooltipStyles}
            title={renderTooltipContent()}
            placement="bottom"
            arrow
            onClose={handleTooltipClose}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive
          >
            <span
              className={clsx(classes.icon, classes.sectionTitleInfoIcon)}
              onClick={() => setIsTooltipOpen(!isTooltipOpen)}
            >
              <InfoOutlined />
            </span>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Typography>
  );
};

SectionTitle.propTypes = {
  technology: PropTypes.string,
  showTabs: PropTypes.bool,
};

export { SectionTitle };
